import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import PostPreview from "../components/PostPreview";
import { Pagination } from "../components/ArchivePagination";

const BlogListing = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;

  return (
    <Layout bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo
        title="Latest News About Online Casinos in Ontario"
        description=" The Ontario gambling market is developing rapidly. Read the latest Ontario casino news and find out more about new brands and games."
        canonical={`https://casinosinontario.com/gambling-news/`}
      />
      {posts &&
        posts.map((post, index) => {
          return (
            <PostPreview
              key={index}
              post={post}
              isLast={index === posts.length - 1}
            />
          );
        })}
      <Pagination
        numPages={pageContext.numPages}
        currentPage={pageContext.currentPage}
        slugPrefix="/articles"
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogListingQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      skip: $skip
      limit: $limit
      sort: { fields: createdDate, order: DESC }
    ) {
      edges {
        node {
          slug
          description {
            description
          }
          image {
            gatsbyImage(width: 200, quality: 70, formats: [AUTO, WEBP], placeholder: NONE)
          }
          title
          seoComponent {
            seoTitle
            seoDescription {
              seoDescription
            }
            seoSlug
          }
          createdDate(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`;

export default BlogListing;
